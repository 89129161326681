<template lang="pug">
Layout
  PageHeader(:title="title", :items="items")
  .row
    .col-lg-12.mb-4.title-box
      TimePeriodTab(:tab="statusTab" @tab-change="changeStatusBar")

    .col-lg-12
      //- 表格
      .card
        .card-body
          .header-row.mb-3
            .header-title 新聞數據列表
            b-button.width-md(variant="primary" to="" )
              span 匯出報表  

          //- Table
          .table-responsive.mb-0
            b-table(
              responsive, 
              ref="dataTable",
              :items="getData", 
              :fields="fields", 
              :per-page="perPage", 
              :current-page="currentPage", 
            )

              //- 排名
              template(v-slot:cell(index)="row") {{ row.index + 1 }}

              //- 稿件來源
              template(v-slot:cell(source)="row")
                span(v-if="!row.item.source") -
                b-button.table-btn(v-else :to="`/external-content-analysis/detail?source=${row.item.source}`")
                  span {{ row.item.source === 'internal' ? '自製稿' : '外稿' }}

              //- Page View(PV)
              template(v-slot:cell(pv)="row") {{ $root.common.formatNumber(row.item.pv, 0) }}

              //- 平均每篇 PV
              template(v-slot:cell(average-pv)="row") {{ $root.common.formatNumber(row.item['average-pv'], 0) }}
              
              //- PV佔比
              template(v-slot:cell(pv-percentage)="row") {{ row.item['pv-percentage'] }}%

              //- 愛心數
              template(v-slot:cell(like)="row") {{ row.item.like }}

              //- 收藏數
              template(v-slot:cell(favorite)="row") {{ row.item.favorite }}
              
              //- 稿件數
              template(v-slot:cell(article)="row") {{ $root.common.formatNumber(row.item.article, 0) }}

              //- 總稿件佔比
              template(v-slot:cell(article-percentage)="row") {{ $root.common.formatNumber(row.item['article-percentage'], 0) }}%

              //- show deatil
              template(v-slot:cell(_showDetails)="row")
                b-button.collapse-icon(variant="link" size="sm" @click="row.toggleDetails" class="mr-2")
                  i(v-if="!row.item._showDetails").ri-arrow-down-s-line
                  i(v-else).ri-arrow-up-s-line

              template(v-slot:row-details="row")
                .bg-child-table
                  //- Table
                  .table-responsive.mb-0
                    b-table(
                      responsive, 
                      ref="dataTable",
                      :items="getData", 
                      :fields="childFields", 
                      :per-page="perPage", 
                      :current-page="currentPage", 
                    )

                      //- 稿件來源
                      template(v-slot:cell(source)="row")
                        span(v-if="!row.item.source") -
                        b-button.table-btn(v-else :to="`/external-content-analysis/detail?source=${row.item.source}`")
                          span {{ row.item.source === 'internal' ? '自製稿' : '外稿' }}

                      //- Page View(PV)
                      template(v-slot:cell(pv)="row") {{ $root.common.formatNumber(row.item.pv, 0) }}

                      //- 平均每篇 PV
                      template(v-slot:cell(average-pv)="row") {{ $root.common.formatNumber(row.item['average-pv'], 0) }}
                      
                      //- PV佔比
                      template(v-slot:cell(pv-percentage)="row") {{ row.item['pv-percentage'] }}%

                      //- 愛心數
                      template(v-slot:cell(like)="row") {{ row.item.like }}

                      //- 收藏數
                      template(v-slot:cell(favorite)="row") {{ row.item.favorite }}
                      
                      //- 稿件數
                      template(v-slot:cell(article)="row") {{ $root.common.formatNumber(row.item.article, 0) }}

                      //- 總稿件佔比
                      template(v-slot:cell(article-percentage)="row") {{ $root.common.formatNumber(row.item['article-percentage'], 0) }}%

          .row.mb-md-2
            .col-12
              div(class="dataTables_paginate paging_simple_numbers float-right")
                ul.pagination.pagination-rounded.mb-0
                  b-pagination(v-model="currentPage", :total-rows="totalRows", :per-page="perPage")
</template>
  
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/Page-header";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import TimePeriodTab from "./components/TimePeriodTab.vue";

const mockData = [
  { 
    source: 'internal',
    pv: 12000,
    'average-pv': 12000,
    'pv-percentage': 12,
    like: 30,
    favorite: 10,
    article: 12000,
    'article-percentage': 30,
    _showDetails: true
  },
  { 
    source: 'external',
    pv: 11000,
    'average-pv': 11000,
    'pv-percentage': 11,
    like: 34,
    favorite: 12,
    article: 11000,
    'article-percentage': 11,
    _showDetails: false
  }
]

/**
 * Starter component
 */
export default {
  name: 'NewsAnalysis',
  data () {
    return {
      title: "外稿資料 / 數據分析",
      items: [{
        text: "數據分析",
        href: "/dashboard",
      },
      {
        text: "外稿資料",
        active: true,
      }
      ],
      tableData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      filter: null,
      filterOn: [],
      sortDesc: false,
      status: false,
      fields: [
        { key: 'index', label: "排名", class: 'width-auto center' },
        { key: 'source', label: "稿件來源", class: 'width-auto center' },
        { key: "pv", label: "總 PV", class: 'width-auto center' },
        { key: "average-pv", label: "平均每篇 PV", class: 'width-auto center' },
        { key: "pv-percentage", label: "PV佔比", class: 'width-auto center' },
        { key: "like", label: "愛心數", class: 'width-auto center' },
        { key: "favorite", label: "收藏數", class: 'width-auto center' },
        { key: "article", label: "總稿件", class: 'width-auto center' },
        { key: "article-percentage", label: "總稿件佔比", class: 'width-auto center' },
        { key: "_showDetails", label: "", class: 'width-auto center' }
      ],
      statusTab: 0,
      searchData: {
        unit: [],
        type: [],
        source: [], 
        editer: ''
      },
      editer: [],
      currentEditer: false,
    };
  },
  computed: {
    childFields() {
      return this.fields.filter(_field => _field.key !== 'index' && _field.key !== '_showDetails')
    }
  },
  methods: {
    // 變更狀態夾
    changeStatusBar (_status) {
      this.statusTab = _status
      this.currentPage = 1
      this.reloadData()
    },
    reloadData () {
      this.$refs.dataTable.refresh()
    },
    // 獲得推播項目清單
    getData (_table, _callback) {
      let vm = this
      _callback(mockData)
      // this.$root.apis.getData({
      //   status_type: this.statusTab,
      //   page: this.currentPage
      // }, function (_response) {
      //   let response = _response.body.data
      //   vm.currentPage = response.page
      //   vm.totalRows = response.total
      //   vm.perPage = response.per_page
      //   _callback(response.items)
      // })
    },
    searchEditerList (_text) {
      let vm = this
      console.log("=> 管理員搜尋:", _text)
      this.$root.apis.getFastManagerList({
        name: _text,
        per_page: 100
      }, function (_response) {
        vm.editer = _response.body.data.items
      })
    },
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
    TimePeriodTab
  }
};
</script>
  
<style lang="sass" scoped>
.card-header
  background: #ffffff
.label-select
  border: none
  padding: 0
  height: auto
  line-height: 1
  font-weight: 600
.header-row
  display: flex
  align-items: center
  .header-title
    margin: 0
    flex: 1
.tab-group
  background: rgba(229,231,235,1)
  padding: 0.5rem 1rem
  button.off
    color: rgba(156,163,175,1)    
    background: rgba(0,0,0,0)
    border-color: rgba(0,0,0,0)
::v-deep .width-auto
  min-width: 7rem
  white-space: nowrap
  vertical-align: middle
  &.center
    text-align: center
::v-deep .table-btn
  text-decoration: underline
  color: #0073b8
  background: none
  border: none
  padding: 0
  &.delete
    color: #333333

::v-deep .collapse-icon
  color: #333333
  background: none
  border: none
  padding: 0
  font-size: 20px
  font-weight: 600
::v-deep .bg-child-table
  background: #ECF7FE
</style>
